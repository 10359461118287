<template>
  <section>
    <b-card>
      <div class="title">
        <p>22.3 B.O. Comp. (Special Period)</p>
      </div>

      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-start mb-1"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            class="mr-1"
            @click="goBackReport"
          >
            回上頁
          </b-button>
          <b-button
            @click="exportToExcel(items, specialText, dataTotal)"
            variant="outline-primary"
            size="sm"
            class="mr-1"
          >
            {{ specialText }}
            Excel
          </b-button>
          <b-button
            v-if="comparison === 'true'"
            variant="outline-primary"
            size="sm"
            @click="exportToExcel(itemsCompare, periodText, dataTotalCom)"
            class="mr-1"
          >
            {{ periodText }}
            Excel
          </b-button>
        </b-col>
        <b-col>
          <b-form-select
            style="width: 200px"
            v-model="loactionVal"
            :options="locationOptions"
          ></b-form-select>
          <b-button variant="primary" @click="searhChange" class="ml-1">
            Search
          </b-button>
        </b-col>
      </b-row>
      <div class="mt-1">
        <template>
          <div v-loading="tableLoading">
            <b-table
              bordered
              responsive
              :items="items"
              :fields="
                $store.state.highlightCol.firTable
                  ? $store.state.newTableFields
                  : fields
              "
              @head-clicked="
                commonHighLightCol(
                  $event,
                  fields,
                  $store.state.newTableFields,
                  'firTable'
                )
              "
            >
              <template #thead-top="">
                <b-tr>
                  <b-th colspan="6">{{ specialText }} </b-th>
                  <b-th colspan="3"
                    >Period Total B.O. :
                    {{ parseFloat(dataTotal).toLocaleString() }}</b-th
                  >
                </b-tr>
              </template>
              <template #cell()="data">
                <div v-if="data.field.numType">
                  {{ parseFloat(data.value).toLocaleString() }}
                </div>
                <div v-else-if="data.field.film" style="width: 220px">
                  {{ data.value }}
                </div>
                <div
                  class="text-nowrap"
                  v-else-if="
                    data.field.key === 'release_date' ||
                    data.field.key === 'release_off'
                  "
                >
                  {{ isValidDate(data.value) }}
                </div>
                <div v-else>
                  {{ data.value }}
                </div>
              </template>
            </b-table>
          </div>
        </template>
      </div>
      <div
        class="my-4"
        v-if="comparison === 'true'"
        v-loading="comparisonLoading"
      >
        <template>
          <div>
            <b-table
              bordered
              responsive
              :items="itemsCompare"
              :fields="
                $store.state.highlightCol.secTable
                  ? $store.state.newSecTableFields
                  : fields
              "
              @head-clicked="
                commonHighLightCol(
                  $event,
                  fields,
                  $store.state.newSecTableFields,
                  'secTable'
                )
              "
            >
              <template #thead-top="">
                <b-tr>
                  <b-th colspan="6">{{ periodText }}</b-th>
                  <b-th colspan="3"
                    >Period Total B.O. :
                    {{ parseFloat(dataTotalCom).toLocaleString() }}</b-th
                  >
                </b-tr>
              </template>
              <template #cell()="data">
                <div v-if="data.field.numType">
                  {{ parseFloat(data.value).toLocaleString() }}
                </div>
                <div v-else-if="data.field.film" style="width: 220px">
                  {{ data.value }}
                </div>
                <div
                  class="text-nowrap"
                  v-else-if="data.field.key === 'release_date'"
                >
                  {{ isValidDate(data.value) }}
                </div>
                <div
                  class="text-nowrap"
                  v-else-if="data.field.key === 'release_off'"
                >
                  {{ isValidDate(data.value) }}
                </div>
                <div v-else>
                  {{ data.value }}
                </div>
              </template>
            </b-table>
          </div>
        </template>
      </div>
      <p>紅色highligh代表有優先場</p>
    </b-card>
  </section>
</template>
<script>
import { getReportPeriod } from "@/api/specialRecord";
//import XLSX from 'xlsx'
import XLSX from "xlsx-js-style";
export default {
  data() {
    return {
      locationOptions: [
        { value: "", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "non Hong Kong", text: "非香港" }
      ],
      loactionVal: "",
      comparisonLoading: false,
      tableLoading: false,
      currentPage: 1,
      selectDropdown: true,
      tabletop: true,
      path: "special-film-record",
      fields: [
        {
          key: "film",
          label: "Film Title",
          sortable: true
        },
        {
          key: "run",
          label: "Run",
          sortable: true
        },
        {
          key: "source",
          label: "Country",
          sortable: true
        },
        {
          key: "release_date",
          label: "Release Date",
          sortable: true
        },
        {
          key: "release_off",
          label: "Off Date",
          sortable: true
        },
        {
          key: "bo",
          label: "Total B.O.",
          sortable: true,
          numType: true
        },
        {
          key: "visits",
          label: "Total ADM.",
          sortable: true,
          numType: true
        },
        {
          key: "period_bo",
          label: "Period B.O.",
          sortable: true,
          numType: true
        },
        {
          key: "accum_bo",
          label: "Accum. B.O.",
          sortable: true,
          numType: true
        }
      ],
      items: [],
      itemsCompare: [],
      dataTotal: "",
      dataTotalCom: "",
      comparison: false,
      startDate: "",
      endDate: "",
      startDatecomparison: "",
      endDatecomparison: "",
      specialText: "",
      periodText: ""
    };
  },
  methods: {
    searhChange() {
      if (this.comparison === "true") {
        this.getReport();
        this.getReportComparison();
      } else {
        this.getReport();
      }
    },
    exportToExcel(itemsList, tableName, total) {
      //tabel list
      const header = [
        "Film Title",
        "Run",
        "Production Country",
        "Visual Format",
        "Release Date",
        "Period B.O.",
        "Accom. B.O."
      ];
      const dataList = [];
      itemsList.map((item) => {
        const list = [];
        list.push(
          item.film,
          item.run,
          item.source,
          item.effect,
          this.excelDateFormat(item.release_date),
          Number(item.period_bo),
          Number(item.accum_bo)
        );
        dataList.push(list);
      });
      dataList.unshift(header);
      const headerData = [
        `Special Period:${tableName}`,
        "",
        "",
        "",
        "",
        "Period Total B.O. :",
        parseFloat(total).toLocaleString()
      ];
      dataList.unshift(headerData);
      console.log(dataList);

      const numList = [
        { title: "F", index: 3 },
        { title: "G", index: 3 }
      ];
      const excelInfo = {
        type: "22.3",
        merge: false,
        setDateValue: false,
        data: itemsList,
        dateCol: [],
        fileName: `B.O. ${tableName}.xlsx`,
        header,
        excelData: dataList,
        numFmt: true,
        numFmtList: numList,
        hasTotal: false
      };
      this.excelFun(excelInfo, "noapi");
    },
    handleLocationChange(val) {
      this.location = val;
    },
    getReport() {
      this.tableLoading = true;
      const data = `&page=1&count=10000&date_start=${this.startDate}&date_end=${this.endDate}&area=${this.loactionVal}`;
      getReportPeriod(data)
        .then((res) => {
          this.dataTotal = res.period_total_bo;
          const arr = [];
          this.items = arr;
          if (res.data && res.data.length > 0) {
            res.data.forEach((el) => {
              const obj = new Object();
              obj.run = el.run_ver;
              obj.film = `${el.film_master.name_chinese}-${el.film_master.name_english}`;
              obj.source = el.film_master.country;
              obj.release_date = el.film.date_release;
              obj.release_off = this.dateOffFormat(
                el.film.date_off,
                el.film.date_release
              );
              obj.bo = el.tg;
              obj.accum_bo = el.tg;
              obj.visits = el.ta;
              obj.period_bo = el.accum_bo;
              obj.effect = el.film.remark_2
                ? `${el.film.remark_1} (${el.film.remark_2})`
                : el.film.remark_1;
              obj._rowVariant =
                new Date(el.film.date_on) < new Date(el.film.date_release)
                  ? "danger"
                  : "";
              arr.push(obj);
            });
          }
          this.items = arr;
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.tableLoading = false;
        });
    },
    getReportComparison() {
      this.comparisonLoading = true;
      const data = `&page=1&count=10000&date_start=${this.startDatecomparison}&date_end=${this.endDatecomparison}&area=${this.loactionVal}`;
      getReportPeriod(data)
        .then((res) => {
          this.dataTotalCom = res.period_total_bo;
          const arr = [];
          this.itemsCompare = arr;
          if (res.data && res.data.length > 0) {
            res.data.forEach((el) => {
              const obj = new Object();
              obj.run = el.run_ver;
              obj.film = `${el.film_master.name_chinese}-${el.film_master.name_english}`;
              obj.source = el.film_master.country;
              obj.release_date = el.film.date_release;
              obj.release_off = this.dateOffFormat(
                el.film.date_off,
                el.film.date_release
              );
              obj.bo = el.tg;
              obj.accum_bo = el.tg;
              obj.visits = el.ta;
              obj.period_bo = el.accum_bo;
              obj.effect = el.film.remark_2
                ? `${el.film.remark_1} (${el.film.remark_2})`
                : el.film.remark_1;
              obj._rowVariant =
                new Date(el.film.date_on) < new Date(el.film.date_release)
                  ? "danger"
                  : "";
              arr.push(obj);
            });
          }

          this.itemsCompare = arr;
          this.comparisonLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.comparisonLoading = false;
        });
    },
    goBackReport() {
      this.$router.push({ name: this.path });
    }
  },
  mounted() {},
  created() {
    this.queryVal = this.$route.query;
    this.specialText =
      this.queryVal.selectText ||
      `${this.queryVal.dateStart} ~ ${this.queryVal.dateEnd}`;
    this.periodText =
      this.queryVal.selectTextCom ||
      `${this.queryVal.compareDateStart} ~ ${this.queryVal.compareDateEnd}`;
    this.comparison = String(this.$route.query.compare);
    this.startDate = this.queryVal.dateStart;
    this.endDate = this.queryVal.dateEnd;
    this.getReport();
    if (this.comparison === "true") {
      this.startDatecomparison = this.queryVal.compareDateStart;
      this.endDatecomparison = this.queryVal.compareDateEnd;
      this.getReportComparison();
    }
  }
};
</script>
<style lang="scss">
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.table-name:hover {
  text-decoration: underline;
}
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
</style>
